import React from "react"

import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import Image from "next/image"
import { createUseStyles, useTheme } from "react-jss"

import GradientText, {
  useGradientStyle,
} from "components/shared/GradientTypography"

import ChooseYourCertificate, { CustomDivider } from "./ChooseYourCertificate"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: "white",
    padding: theme.spacing(8, 0),
  },
  section: {
    marginTop: 96,
    [theme.breakpoints.down("xs")]: {
      marginTop: 64,
    },
  },
  img: {
    position: "relative",
  },
  blue: {
    color: theme.colors.primary[500],
  },
}))

const MagicHappens = () => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  // to remove shadow just send false
  const gradient = useGradientStyle(
    "linear-gradient(90deg, #9A7BBA 0%, #49B7CC 100%)",
    false
  )
  const gradient2 = useGradientStyle(
    "linear-gradient(90deg, #9A7BBA 0%, #49B7CC 100%)",
    false
  )
  const gradientOrange = useGradientStyle(
    "linear-gradient(90deg, #E5A256 0%, #97BC62 100%)",
    false
  )

  return (
    <div className={classes.root}>
      <Container>
        <div>
          <Typography className="mb-2" variant="title1">
            How Does the <span style={gradient}>Magic</span> Happen?
          </Typography>

          <Typography
            className="mb-2"
            style={{ maxWidth: 595 }}
            variant="subtitle1"
          >
            Your Suraasa journey has been designed after talking to over
            100,000+ teachers. You get qualified and learn in just 10 months,
            even if you are already working somewhere.
          </Typography>
        </div>

        <div className={classes.section}>
          <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
            <div>
              <GradientText
                className="mb-2"
                gradient="linear-gradient(90deg, #9A7BBA 0%, #E5A256 100%)"
                shadow={false}
                style={{ maxWidth: 376 }}
                variant="title1"
              >
                Live Online Classes and Self-Paced Content
              </GradientText>

              <Typography style={{ maxWidth: 336 }} variant="largeBody">
                Attend live 4-hour online classes on weekends led by professors
                from the US, UK, UAE, India. Access high quality pre-recorded
                videos & reading material on Suraasa.
              </Typography>
            </div>
            <div className={classes.img}>
              <Image
                alt="image"
                height={412}
                src="/assets/landing_page/v2/magic-happens/build-suraasa-profile.webp"
                width={539}
              />
            </div>
          </div>

          <div>
            {!isXsDown && (
              <CustomDivider
                className="mt-3 mb-3"
                gradient="linear-gradient(90deg, #9A7BBA 0%, #E5A256 100%)"
              />
            )}

            <div className="flex flex-col items-start justify-between gap-2 sm:items-center sm:flex-row">
              <Image
                alt="icon"
                height={40}
                src="/assets/landing_page/v2/magic-happens/platform-icons.webp"
                width={132}
              />
              <Typography style={{ maxWidth: 366 }} variant="subtitle2">
                Access your learning and progress on any device through our web
                and mobile apps.
              </Typography>
            </div>
            {isXsDown && (
              <CustomDivider
                className="mt-3"
                gradient="linear-gradient(90deg, #9A7BBA 0%, #E5A256 100%)"
              />
            )}
          </div>
        </div>

        <div className={classes.section}>
          <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
            <div>
              <div style={{ maxWidth: 376 }}>
                <Typography display="inline" variant="title1">
                  <span style={gradient2}>Placement Support</span> with Top
                  Schools
                </Typography>
              </div>

              <Typography
                className="mt-2"
                style={{ maxWidth: 336 }}
                variant="largeBody"
              >
                Some of the most premium and high-paying schools in your city
                and abroad prefer hiring Suraasa Qualified Teachers. We push
                your profile for relevant job opportunities as part of placement
                support.
              </Typography>
              <Typography
                className="mt-2"
                color="onSurface.500"
                style={{ maxWidth: 336 }}
                variant="smallBody"
              >
                Can vary depending on individual performance and other factors.
              </Typography>
            </div>
            <div className={classes.img}>
              <Image
                alt="image"
                height={412}
                src="/assets/landing_page/v2/magic-happens/interviews.webp"
                width={539}
              />
            </div>
          </div>

          <div>
            {!isXsDown && (
              <CustomDivider
                className="mt-3 mb-3"
                gradient="linear-gradient(90deg, #9A7BBA 0%, #49B7CC 100%)"
              />
            )}

            <div className="flex flex-col items-start justify-between gap-2 sm:items-center sm:flex-row">
              <Image
                alt="icon"
                height={48}
                src="/assets/landing_page/v2/magic-happens/twitter-verified-badge.webp"
                width={48}
              />
              <Typography style={{ maxWidth: 366 }} variant="subtitle2">
                Impress people wherever you go with your{" "}
                <span className={classes.blue}>Suraasa Verified</span> badge.
              </Typography>
            </div>
            {isXsDown && (
              <CustomDivider
                className="mt-3"
                gradient="linear-gradient(90deg, #9A7BBA 0%, #49B7CC 100%)"
              />
            )}
          </div>
        </div>

        <div className={classes.section}>
          <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
            <div>
              <div style={{ maxWidth: 336 }}>
                <Typography display="inline" variant="title1">
                  <span style={gradientOrange}>Skill Evidence</span> Building
                  for Your Profile
                </Typography>
              </div>

              <Typography
                className="mt-2"
                style={{ maxWidth: 336 }}
                variant="largeBody"
              >
                You focus a lot of time on assignments (skill evidence) to
                develop practical and critical thinking skills. Each evidence
                gets added to your skills portfolio, that schools absolutely
                love to see.
              </Typography>
            </div>
            <div className={classes.img}>
              <Image
                alt="image"
                height={412}
                src="/assets/landing_page/v2/magic-happens/evidence.webp"
                width={539}
              />
            </div>
          </div>

          <div>
            {!isXsDown && (
              <CustomDivider
                className="mt-3 mb-3"
                gradient="linear-gradient(90deg, #E5A256 0%, #97BC62 100%)"
              />
            )}

            <div className="flex flex-col items-start justify-between gap-2 sm:items-center sm:flex-row">
              <Image
                alt="icon"
                height={43}
                src="/assets/landing_page/v2/magic-happens/skill-icon.webp"
                width={43}
              />
              <Typography style={{ maxWidth: 366 }} variant="subtitle2">
                Your Suraasa Profile a.k.a. your digital resume leaves people in
                awe even while you sleep.
              </Typography>
            </div>
            {isXsDown && (
              <CustomDivider
                className="mt-3"
                gradient="linear-gradient(90deg, #E5A256 0%, #97BC62 100%)"
              />
            )}
          </div>
        </div>

        <ChooseYourCertificate />
      </Container>
    </div>
  )
}

export default MagicHappens
