/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react"

import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import { createUseStyles, useTheme } from "react-jss"

import { getPlatformURL } from "utils/helpers"

const useStyles = createUseStyles(theme => ({
  sectionRoot: {
    backgroundColor: "white",
    padding: theme.spacing(8, 0),
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    marginBottom: theme.spacing(1.5),
    fontFamily: "Lora",
    fontWeight: "500 !important",
    marginRight: "auto",
    marginLeft: "auto",

    position: "relative",
  },
  quoteLeft: {
    color: theme.colors.onSurface[200],
    fontSize: 80,
    position: "absolute",
    left: -30,
    top: -20,

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  quoteRight: {
    color: theme.colors.onSurface[200],
    fontSize: 80,
    position: "absolute",
    right: -30,
    top: -20,

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}))

const data: {
  title: string
  subtitle: string
  icon: string
  link?: string
  gradient: string
}[] = [
  {
    title: "Masterclasses",
    subtitle: "Learn in Free Fortnightly Masterclasses by our professors",
    icon: "/assets/landing_page/v2/free-resources/play-icon.webp",
    link: "https://suraasa.com/masterclasses",
    gradient: "linear-gradient(90deg, #E5A256 0%, #97BC62 100%) !important",
  },
  {
    title: "Jobs",
    subtitle: "Find & Apply for Teaching Jobs at Top Schools",
    icon: "/assets/landing_page/v2/free-resources/bag-icon.webp",
    link: "https://jobs.suraasa.com/explore",
    gradient: "linear-gradient(90deg, #49B7CC 0%, #97BC62 100%)",
  },
  {
    title: "Free Resources",
    subtitle: "Explore from a library of 100+ curated free resources",
    icon: "/assets/landing_page/v2/free-resources/docs-icon.webp",
    link: "https://suraasa.com/resources",
    gradient: "linear-gradient(90deg, #60A5FA 0%, #3B82F6 100%)",
  },
  {
    title: "Instagram",
    subtitle:
      "Get your daily dose of motivation & inspiration for your teaching journey",
    icon: "/assets/landing_page/v2/free-resources/insta-icon.webp",
    gradient:
      "linear-gradient(270deg, #E64A4B 2.78%, #DE278B 48.3%, #8427EB 97%)",
  },
]

const useCardStyles = createUseStyles(theme => ({
  "@keyframes diagonal-slide": {
    "0%": { opacity: 1, transform: "translate(0px,0px) scale(1)" },
    "25%": { opacity: 0, transform: "translate(5px,-5px) scale(0.9)" },
    "26%": { opacity: 0, transform: "translate(-5px,5px) scale(0.9)" },
    "55%": { opacity: 1, transform: "translate(0px,0px) scale(1)" },
  },

  cardRoot: {
    textDecoration: "none",
    position: "relative",
    maxWidth: 415,
    transition: "transform 0.4s,background 1s ease-out,padding 0.4s ease-out",
    color: "white",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    background: theme.colors.onSurface[900],
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2.5),
    overflow: "hidden",
    "&:hover": {
      "& $circleArrow": {
        backgroundColor: "white",
      },
      "& .arrow": {
        filter: "invert(1)",
        animationName: "$diagonal-slide",
        animationDuration: "1s",
        animationIterationCount: "1",
        animationTimingFunction: "linear",
      },
      "& $bottomColor": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "none",
      width: "100%",
    },
  },

  circleArrow: {
    border: "2px solid white",
    borderRadius: "50%",
    width: 26.67,
    height: 26.67,
    bottom: 16,
    left: 16,
    cursor: "pointer",
    transition: "background-color 0.4s ease-out",
    [theme.breakpoints.down("sm")]: {
      bottom: "auto",
      left: "auto",
      top: 16,
      right: 16,
    },
  },
  bottomColor: {
    position: "absolute",
    height: 8,
    width: "100%",
    left: 0,
    bottom: 0,
    zIndex: 1,
    borderBottomLeftRadius: "32px",
    borderBottomRightRadius: "32px",
    opacity: 0,
    transition: "opacity 0.4s",
  },
}))

const Card = ({
  title,
  subtitle,
  icon,
  link,
  gradient,
  className,
}: {
  title: string
  subtitle: string
  icon: string
  link?: string
  gradient: string
} & { className?: string }) => {
  const classes = useCardStyles()
  const theme = useTheme<Theme>()
  const isSm = useMediaQuery(theme.breakpoints.down("sm"))
  const url = isSm
    ? "https://suraasa.co/instagram"
    : getPlatformURL("suraasa", "/instagram")
  return (
    <a
      className={clsx(classes.cardRoot, className)}
      href={link || url}
      rel="noopener noreferrer"
      target="_blank"
    >
      <div
        className={classes.bottomColor}
        style={{
          background: gradient,
        }}
      />
      <div className="flex justify-between mb-2">
        <Image alt="icon" height={48} src={icon} width={48} />
        <div
          className={clsx(
            "grid items-center justify-center",
            classes.circleArrow
          )}
        >
          <Image
            alt="arrow"
            className="arrow"
            height={10}
            src="/assets/landing_page/v2/free-resources/arrow.svg"
            width={9}
          />
        </div>
      </div>

      <Typography className="mb-0.5" variant="title3">
        {title}
      </Typography>
      <Typography variant="largeBody">{subtitle}</Typography>
    </a>
  )
}

const FreeResources = () => {
  const classes = useStyles()

  return (
    <div className={classes.sectionRoot}>
      <Container>
        <div className={classes.center}>
          <Typography
            className={classes.title}
            color="onSurface.800"
            style={{ maxWidth: 490 }}
            textAlign="center"
            variant="title2"
          >
            <img
              alt="quote"
              className={classes.quoteLeft}
              src="/assets/homepage/v2/start-quote.svg"
            />
            I don't want to invest in upskilling yet. How else can you help me?
            <img
              alt="quote"
              className={classes.quoteRight}
              src="/assets/homepage/v2/end-quote.svg"
            />
          </Typography>
          <Typography
            className="mx-auto"
            color="onSurface.500"
            style={{ maxWidth: 582 }}
            textAlign="center"
            variant="body"
          >
            That's a great question. Experience a ton of free value on Suraasa
            platform. Invest into a programme only when you are ready.
          </Typography>
        </div>
        <div className="grid grid-cols-12 gap-2 mt-4">
          {data.map(d => (
            <Card
              {...d}
              className="col-span-12 md:col-span-3 sm:col-span-6"
              key={d.title}
            />
          ))}
        </div>
      </Container>
    </div>
  )
}

export default FreeResources
