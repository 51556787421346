/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect } from "react"

import Glide from "@glidejs/glide"
import { Container, Theme, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Link from "next/link"
import { createUseStyles, useTheme } from "react-jss"

import { Clock, Group } from "iconoir-react"

import { CourseItem } from "api/resources/learn/types"
import GradientText from "components/shared/GradientTypography"

const useStyles = createUseStyles(theme => ({
  sectionRoot: {
    backgroundColor: theme.colors.onSurface[50],
    padding: theme.spacing(8, 0),
    overflow: "hidden",
  },
  // slide: {
  //   transform: "scale(1.2)",
  // },

  sliderContainer: {
    width: "100%",
    overflow: "hidden",
    background: theme.colors.surface[50],
  },
  sliderControl: {
    width: "8px",
    height: "8px",
    borderRadius: "50%",
    background: theme.colors.surface[300],

    "&[class*=active]": {
      background: "#453E1B",
    },
  },
  rotate: {
    transform: "rotate(180deg)",
  },
}))

const CpdSection = ({ courses = [] }: { courses: CourseItem[] }) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  useEffect(() => {
    const glide = new Glide("#slider", {
      type: "slider",
      gap: 8,
      perView: 3,
      rewind: false,
      focusAt: 0,
      bound: true,
      peek: { before: 0, after: 50 },
      breakpoints: {
        [theme.breakpoints.values.lg]: {
          perView: 3,
        },
        [theme.breakpoints.values.md]: {
          perView: 2,
        },
        [theme.breakpoints.values.sm]: {
          perView: 1,
        },
      },
    }).mount()

    return () => {
      glide.destroy()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses])

  return (
    <div className={classes.sectionRoot} id="courses-and-certificates">
      <style
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
          button .white {
             display: none;
          }
          button .black {
             display: block;
          }
          .glide__arrow--disabled .white {
             display: block;
          }
          .glide__arrow--disabled .black {
             display: none;
          }
          `,
        }}
      />
      <Container>
        <GradientText
          className="mb-1"
          gradient="linear-gradient(90deg, #60A5FA 0%, #3B82F6 100%)"
          style={{ maxWidth: 520 }}
          variant="title2"
        >
          Continuous Professional Development (CPD) Courses & Certifications
        </GradientText>
        <Typography style={{ maxWidth: 520 }} variant="body">
          Already qualified but looking to add skills and certifications to your
          profile? Choose from many of our CPD courses for teachers.
        </Typography>
      </Container>

      <div id="slider" style={{ position: "relative" }}>
        <Container>
          <div className="mt-4 glide__track" data-glide-el="track">
            <ul
              className="flex glide__slides"
              style={{ listStyleType: "none" }}
            >
              {courses.map((item, index) => (
                <Card key={index} {...item} />
              ))}
            </ul>
          </div>

          <div className="flex justify-end mt-4" data-glide-el="controls">
            <button
              className={clsx(classes.rotate, "mr-1")}
              data-glide-dir="<"
              id="left"
            >
              <img
                alt="arrow left"
                className="white"
                src="/assets/homepage/v2/white-arrow.svg"
              />
              <img
                alt="arrow left"
                className="black"
                src="/assets/homepage/v2/black-arrow.svg"
              />
            </button>
            <button data-glide-dir=">" id="right">
              <img
                alt="arrow right"
                className="white"
                src="/assets/homepage/v2/white-arrow.svg"
              />
              <img
                alt="arrow right"
                className="black"
                src="/assets/homepage/v2/black-arrow.svg"
              />
            </button>
          </div>
        </Container>
      </div>
    </div>
  )
}

const useCardStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: "white",
    border: `1px solid ${theme.colors.onSurface[200]}`,
    borderRadius: theme.spacing(1),
    "&:hover": {
      cursor: "pointer",
    },
  },

  img: {
    width: "100%",
    maxHeight: 151,
    objectFit: "cover",
  },

  dot: {
    backgroundColor: "#A0A0A0",
    width: 5,
    height: 5,
    borderRadius: "50%",
    margin: theme.spacing(0, 1),
  },

  contentRoot: {
    height: 107,
    padding: theme.spacing(1.5, 2, 2.5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}))

const Card = ({
  name,
  duration,
  numberOfEnrollments,
  image,
  slug,
}: CourseItem) => {
  const classes = useCardStyles()

  return (
    <li className={clsx(classes.root, "slide")}>
      <Link href={`/store/courses/${slug}`}>
        <div>
          <div>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              alt="course"
              className={classes.img}
              src={image || ""}
              onError={e => {
                e.currentTarget.src = "/assets/course-placeholder.svg"
              }}
            />
          </div>

          <div className={classes.contentRoot}>
            <Typography variant="subtitle2">{name}</Typography>

            <div className="flex items-center">
              <div className="flex items-center">
                <Group className="mr-1" />
                <Typography color="onSurface.600" variant="strongSmallBody">
                  {numberOfEnrollments} Learners
                </Typography>
              </div>
              <div className={classes.dot} />
              <div className="flex items-center">
                <Clock className="mr-1" />
                <Typography color="onSurface.600" variant="strongSmallBody">
                  {duration} Days
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default CpdSection
