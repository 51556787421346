/* eslint-disable no-console */
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getRemoteConfig, RemoteConfig } from "firebase/remote-config"

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(config)

// Initialize Remote Config and get a reference to the service
export const remoteConfig: RemoteConfig | undefined =
  typeof window === "undefined" ? undefined : getRemoteConfig(app)

if (remoteConfig) {
  // Set default values
  remoteConfig.defaultConfig = {
    ITO_LANDING_PAGE: "",
  }
}

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app)
