import React, { useEffect } from "react"

import { Theme, useMediaQuery } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { useTheme } from "react-jss"

import NoSSR from "components/shared/NoSSR"

import CTAButton from "./shared/CTAButton"

const StickyCTAButton = () => {
  const theme = useTheme<Theme>()
  const [showBar, setShowBar] = React.useState(false)

  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  useEffect(() => {
    const listenToScroll = () => {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop
      if (winScroll > 300) {
        setShowBar(true)
      } else {
        setShowBar(false)
      }
    }

    window.addEventListener("scroll", listenToScroll)

    return () => {
      window.removeEventListener("scroll", listenToScroll)
    }
  }, [])

  return (
    <div
      className={clsx(
        "px-2 pt-2 pb-1 gap-2 bg-white transition-opacity duration-[350ms] opacity-0 fixed bottom-0 z-10 w-full",
        isXs && showBar ? "opacity-[1]" : ""
      )}
    >
      <NoSSR>
        <CTAButton fullWidth />
      </NoSSR>
    </div>
  )
}

export default StickyCTAButton
