import { Container, Typography } from "@suraasa/placebo-ui"
import Image from "next/image"
import Link from "next/link"
import Graphic from "public/assets/landing_page/v2/cuate.webp"
import { createUseStyles } from "react-jss"

import { useGradientStyle } from "components/shared/GradientTypography"
import PreserveQueryParams from "components/shared/PreserveQueryParams"

import CTAButton from "./shared/CTAButton"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.colors.onSurface[900],
    color: "white",
    padding: theme.spacing(8, 0),
  },
  maxWidth: {
    maxWidth: "340px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "217px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  description: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "19px",
    display: "block",
  },
  bookACall: {
    background: "linear-gradient(90deg, #60A5FA 0%, #3B82F6 100%)",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      margin: "auto",
    },
  },
  videoContainer: {
    height: 348.47,
    position: "relative",
    maxWidth: 485,
    width: "100%",
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      height: 260,
    },
  },
  videoGradientBackground: {
    top: "50%",
    left: "50%",
    width: "70%",
    transform: "translate(-50%, -50%)",
    filter: "blur(100px)",
    height: "70%",
    position: "absolute",
    background: "linear-gradient(90deg, #60A5FA 0%, #3B82F6 100%)",
  },
  videoPlayer: {
    zIndex: 10,
    position: "absolute",
    width: "auto",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}))

const TalkToAMentorSection = () => {
  const classes = useStyles()
  const gradient = useGradientStyle(
    "linear-gradient(90deg, #60A5FA 0%, #3B82F6 100%)"
  )
  const points = [
    {
      heading: "Honest Career Guidance",
      text: "No one will suggest you any specific paid course if you actually don't need it.",
    },
    {
      heading: "30 mins, Online",
      text: "Share all you want in your career. We'll offer undivided attention and unmatched advice.",
    },
    {
      heading: "Cost? It's on us!",
      text: "While you may need to invest later in growing your skills, the counselling session is our way of helping you take the first step",
    },
  ]
  return (
    <div className={classes.root}>
      <Container>
        <div className="mb-6">
          <Typography className="mb-1" textAlign="center" variant="title1">
            Talk to a Suraasa Mentor
          </Typography>
          <Typography
            color="surface.100"
            textAlign="center"
            variant="largeBody"
          >
            All your career-related questions answered by dedicated counsellors
          </Typography>
        </div>
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12 md:col-span-5 col-start-0">
            <div className="flex flex-col gap-3 mb-4 md:flex-col sm:flex-row">
              {points.map((item, i) => (
                <div key={i}>
                  <Typography
                    className="mb-1"
                    style={gradient}
                    variant="title2"
                  >
                    {item.heading}
                  </Typography>
                  <span className={classes.description}>{item.text}</span>
                </div>
              ))}
            </div>
            <div className="flex flex-col">
              <CTAButton
                containerClassName="mt-4 mx-auto md:mx-0"
                label="Book a call"
              />
            </div>
          </div>
          <PreserveQueryParams
            href="/talk-to-a-mentor"
            render={href => (
              <Link
                className="col-span-12 md:col-span-7 md:col-start-6 col-start-0"
                href={href}
                style={{
                  gridRow: 1,
                }}
              >
                <div className={classes.videoContainer}>
                  <Image
                    alt="graphic"
                    className="object-cover h-full"
                    src={Graphic}
                  />

                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  {/* <video
                    className={classes.videoPlayer}
                    controls={false}
                    src="https://assets.suraasa.com/misc/mentorship-session-preview.mp4"
                    style={{ borderRadius: 18 }}
                    width="100%"
                    autoPlay
                    disablePictureInPicture
                    loop
                    muted
                    playsInline
                  />
                  <div className={classes.videoGradientBackground} /> */}
                </div>
              </Link>
            )}
          />
        </div>
      </Container>
    </div>
  )
}

export default TalkToAMentorSection
