import React, { ReactNode } from "react"

import { Accordion, AccordionItem } from "@nextui-org/accordion"
import { Button, Container, Divider, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { ArrowRight, NavArrowDown } from "iconoir-react"

import { getPlatformURL } from "utils/helpers"

import CTAButton from "./shared/CTAButton"

const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    padding: theme.spacing(8, 0),
  },

  divider1: {
    // height: "1px",
  },

  learnMoreBtn: {
    background: theme.colors.decorative.four[100],
  },

  accordionRoot: {
    "& p": { textAlign: "left" },
    "& svg": {
      color: "black",
    },
    "& button, div": {
      paddingLeft: 0,
    },
    "& button": {
      padding: theme.spacing(3, 0),
    },
  },
}))

type FAQ = {
  question: string
  answer: ReactNode
}

function FaqCard({
  question,
  answer,
  showBottomLine = true,
}: {
  question: string
  answer: ReactNode
  showBottomLine?: boolean
}) {
  const classes = useStyles()
  return (
    <>
      <Accordion>
        <AccordionItem
          classNames={{
            content: "pt-0",
          }}
          indicator={<NavArrowDown />}
          title={question}
        >
          {answer}
        </AccordionItem>
      </Accordion>
      {showBottomLine && <Divider className={clsx(classes.divider1)} />}
    </>
  )
}

const advertisingFAQs: FAQ[] = [
  {
    question: "What is PgCTL?",
    answer: (
      <Typography>
        Professional Graduate Certificate in Teaching & Learning is an
        internationally-accredited program to develop critical pedagogical &
        classroom management skills to assistant teachers, teachers, and teacher
        coordinators.
        <br />
        <br />
        The practical skills have been derived by studying day-to-day struggles
        of close to 75,000 teachers in 1,500+ schools in 100+ cities, whom we
        had a privilege to train in different capacities.
        <br />
        <br />
        The program found very high relevance worldwide, and hence it is
        endorsed at Level 6 (Bachelor Level) by ATHE, an Ofqual approved
        awarding body. PgCTL* comes with a Dual certification that helps the
        teachers in meeting regulatory requirements in different countries. For
        Dubai PgCTL* comes with a ATHE UK Level 6 Diploma in Teaching which is
        an UK Ofqual regulated and KHDA approved and accepted Teacher
        preparation qualification.
      </Typography>
    ),
  },
  {
    question: "What is Suraasa?",
    answer: (
      <Typography>
        Suraasa is an international organization that helps teachers achieve
        greater professional and financial growth in their careers. We equip
        teachers with the pedagogical skills and resources that enable them to
        experience career satisfaction and continuous advancement.
      </Typography>
    ),
  },
  {
    question: "Who is the PgCTL programme for?",
    answer: (
      <Typography>
        The PgCTL* program has been designed to inculcate the necessary pedagogy
        skills in existing and new assistant teachers, subject teachers, teacher
        coordinators and head of departments. Our counselors can help you find
        the right program for you.
      </Typography>
    ),
  },
  {
    question:
      "Am i eligible to enroll for PgCTL? What all qualifications do I need beforehand?",
    answer: (
      <Typography>
        PgCTL* is for aspiring or existing teachers like you who wish to create
        high-impact learning environments.
        <br /> <br />
        A candidate needs to meet all of the following conditions to be eligible
        for PgCTL*:
        <br />
        <br />
        {[
          `Currently employed in an appropriate teaching role Or Looking for a teaching job right now`,
          "Holds at least an Undergraduate Degree (Bachelors in some subject) or equivalent from a regionally accepted institution. Masters or PhDs in your subjects are optional and are more than welcome.",
          "Has English proficiency at a level of B2 on the Common European Framework of Reference or equivalent (e.g. IELTS level 6)",
        ].map((point, i) => (
          <li key={i}>{point}</li>
        ))}
        <br />
        In addition, we qualify a candidate who demonstrates strong desire to:
        <br />
        <br />
        {[
          `Grow professionally in teaching career`,
          "Succeed as a school leader",
          "Deliver a positive change in children’s learning",
          "Commit to make regular effort towards their development",
        ].map((point, i) => (
          <li key={i}>{point}</li>
        ))}
        Not sure if you meet these requirements? Talk to our counsellor to find
        that out.
      </Typography>
    ),
  },
  {
    question: "How long will I take to finish PgCTL?",
    answer: (
      <Typography>
        The duration of the program is 10 months. You need to commit 8 hours
        every week to reap maximum benefits from PgCTL.
        <br />
        <br />
        {[
          "4 hours of live classes every week (at a fixed time you choose upfront)",
          "2 hours on online assessments and assignments (at a time of your choice)",
          "  2 hours on self-study on Suraasa’s online platform (at a time of your choice)",
        ].map((point, i) => (
          <li key={i}>{point}</li>
        ))}
      </Typography>
    ),
  },
  {
    question: "Will I be able to work/teach alongside PgCTL?",
    answer: (
      <Typography>
        Yes, PgCTL’s study modules are designed to go hand-in-hand with the busy
        schedule of working teacher professionals since it has a very practical
        curriculum.
        <br />
        In case you are not teaching right now, you will get apprenticeship and
        placement opportunities in the 4 months of starting the program.
      </Typography>
    ),
  },
  {
    question: "Do you offer scholarships?",
    answer: (
      <Typography>
        Yes, we offer scholarships based on academic excellence and past work
        experience, if any. Eligibility for the same can be confirmed after
        filling up the application form.
      </Typography>
    ),
  },
]

const generalFAQs: FAQ[] = [
  {
    question: "Are Suraasa’s courses accredited by any authority?",
    answer: (
      <Typography variant="body">
        Each Suraasa course comes with a certificate by Suraasa, a globally
        recognised teacher education institution. Further, we offer multiple
        certification options accredited by various education authorities across
        the world. You can choose a relevant certificate based on where you want
        to teach.
        <br /> <br />
        For eg. If you want to teach in the UAE or in the UK, you can opt for
        our ATHE-accredited certificates.
      </Typography>
    ),
  },
  {
    question: "I am interested. How can I contact you?",
    answer: (
      <Typography variant="body">
        If you are interested in enrolling into a Suraasa upskilling programme,
        you can schedule a call with your Suraasa mentor at{" "}
        <a
          href="https://www.suraasa.com/talk-to-a-mentor"
          rel="noreferrer"
          style={{
            textDecoration: "none",
            color: "#4666F6",
          }}
          target="_blank"
        >
          www.suraasa.com/talk-to-a-mentor
        </a>{" "}
        to find out the right programme for you.
      </Typography>
    ),
  },
  {
    question: "Can you help me find a teaching job?",
    answer: (
      <Typography variant="body">
        Yes, we certainly can. There are 2 routes to finding jobs through
        Suraasa:
        <br />
        <br />
        <b>a) Route 1: Free Job Platform - </b>In this route, you can simply
        sign up and create a profile on our free job platform for teachers and
        schools. Schools post their job openings and you can apply for them.
        Schools will also be able to access your profile in a database of
        teachers and invite you.
        <br />
        <br />
        <b>b) Route 2: Suraasa Employment Support - </b>In this route, you enrol
        into one or many of our programmes which come with 100% interview
        guarantee. We verify your skills, prepare you for interviews, and
        further recommend your profile to our partner schools.
      </Typography>
    ),
  },
  {
    question: "Do schools accept your courses?",
    answer: (
      <Typography variant="body">
        While this decision varies with the policies of different schools, most
        international schools across all curricula accept and appreciate the
        value of our courses.
      </Typography>
    ),
  },
  {
    question: "Is Suraasa free?",
    answer: (
      <Typography variant="body">
        Suraasa’s upskilling programs for teachers are paid. However, the
        Suraasa provides free career growth mentoring to teachers for free.
      </Typography>
    ),
  },
]

function FAQs({
  isAdvertisementPage = false,
}: {
  isAdvertisementPage?: boolean
}) {
  const classes = useStyles()
  const faqs = isAdvertisementPage ? advertisingFAQs : generalFAQs

  return (
    <div className={classes.root}>
      <Container>
        <div className="grid grid-cols-12 gap-3">
          <div className="col-start-1 col-end-13 md:col-start-2 md:col-end-12">
            <Typography className="mb-8" textAlign="center" variant="title1">
              Frequently Asked Questions
            </Typography>
            {faqs.map((faq, i) => (
              <FaqCard answer={faq.answer} key={i} question={faq.question} />
            ))}

            {!isAdvertisementPage && (
              <>
                {/* <Divider className={clsx(classes.divider1, "mt-4 mb-0.75")} /> */}
                <div className="flex justify-end">
                  <Button
                    color="black"
                    component="a"
                    endAdornment={<ArrowRight />}
                    href={getPlatformURL("suraasa", "/faqs")}
                    target="_blank"
                    variant="text"
                  >
                    View All FAQs
                  </Button>
                </div>
              </>
            )}

            {/* <div className="flex items-center flex-col">
              <Typography
                className="mt-5 mb-2.25"
                textAlign="center"
                variant="body"
              >
                Have more questions? Let's take them one-to-one.
              </Typography>
              <CTAButton />
            </div> */}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default FAQs
