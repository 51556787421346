import { useEffect } from "react"

import { Typography } from "@suraasa/placebo-ui"
import Link from "next/link"
import { toast, ToastContainer } from "react-toastify"

import { BROWSER_STORAGE_KEYS } from "utils/constants"
import useDetectCountry from "utils/hooks/useDetectCountry"
import {
  getTTMRealtimeRegisteredUsers,
  RealTimeAlertUser,
} from "utils/ito/2023/firebaseApi"

import "react-toastify/dist/ReactToastify.css"

const DEFAULT_TOAST_DURATION = 5 * 1000

function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1) + min) // The maximum is inclusive and the minimum is inclusive
}

// First alert after 15 seconds
const FIRST_ALERT_DURATION = 15 * 1000

const getDurationDisplay = (minutes: number) => {
  if (minutes <= 1) {
    return "just now"
  }

  if (minutes > 10) {
    return `few mins ago`
  }

  return `${minutes} mins ago`
}

const ToastBody = ({ name, location, timestamp }: RealTimeAlertUser) => {
  const diffInMinutes = Math.floor((Date.now() - timestamp) / 1000 / 60)
  return (
    <Link href="https://suraasa.co/KNFA">
      <Typography style={{ color: "#050124" }} variant="strongSmallBody">
        {location
          ? `${name} from ${location} scheduled a mentoring session!`
          : `${name} scheduled a mentoring session!`}
      </Typography>
      <Typography color="onSurface.500" variant="smallBody">
        {getDurationDisplay(diffInMinutes)}
      </Typography>
    </Link>
  )
}
const RealtimeAlerts = () => {
  const country = useDetectCountry()

  const sendAlert = (alertData?: Array<RealTimeAlertUser>) => {
    if (!alertData) {
      sessionStorage.removeItem(BROWSER_STORAGE_KEYS.ttmRegisteredUsers)
      // fetch the data again here and restart alert
      return
    }
    const alertDataArray = [...alertData]
    const dataToShow = alertDataArray?.shift()
    sessionStorage.setItem(
      BROWSER_STORAGE_KEYS.ttmRegisteredUsers,
      JSON.stringify(alertDataArray)
    )
    if (!dataToShow) {
      sessionStorage.removeItem(BROWSER_STORAGE_KEYS.ttmRegisteredUsers)
      // fetch the data again here and restart alert
      return
    }
    const randomInt = getRandomIntInclusive(60, 120)
    toast(<ToastBody {...dataToShow} />, {
      containerId: "realTimeAlertsTTM",
      toastId: dataToShow.name,
      className: "!rounded-xl !ml-auto !w-[244px] md:!w-auto",
    })

    const duration = randomInt * 1000 + DEFAULT_TOAST_DURATION
    console.info(
      `> Queuing next registration alert after ${duration / 1000}s: ${
        dataToShow.name
      }`
    )
    setTimeout(() => {
      sendAlert(alertDataArray)
    }, duration)
  }

  useEffect(() => {
    if (country === undefined) return
    let timeout: ReturnType<typeof setTimeout>
    const registeredUsersFromSessionStorage = sessionStorage.getItem(
      BROWSER_STORAGE_KEYS.ttmRegisteredUsers
    )

    const fetch = async () => {
      const res = await getTTMRealtimeRegisteredUsers(country ?? null)
      if (res && Array.isArray(res)) {
        sessionStorage.setItem(
          BROWSER_STORAGE_KEYS.ttmRegisteredUsers,
          JSON.stringify(res)
        )
        console.info(
          `> Next registration alert after ${FIRST_ALERT_DURATION / 1000}s`
        )
        timeout = setTimeout(() => {
          sendAlert(res)
        }, FIRST_ALERT_DURATION)
      }
    }

    if (!registeredUsersFromSessionStorage) {
      fetch()
      return
    }

    const parsedData = JSON.parse(
      registeredUsersFromSessionStorage
    ) as RealTimeAlertUser[]

    console.info(
      `> Next registration alert after ${FIRST_ALERT_DURATION / 1000}s`
    )
    timeout = setTimeout(() => {
      sendAlert(parsedData)
    }, FIRST_ALERT_DURATION)

    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  return (
    <>
      {/* <button
        className="fixed z-50 p-2 text-white bg-black rounded-xl right-2 top-15"
        onClick={() => {
          toast(
            <ToastBody
              id="1"
              location=""
              name="John Doe from Delhi, India just registered"
            />,
            {
              containerId: "realTimeAlertsTTM",
              className: "!rounded-xl !ml-auto !w-[244px] md:!w-auto",
            }
          )
        }}
      >
        Show Toast
      </button> */}

      <ToastContainer
        autoClose={DEFAULT_TOAST_DURATION}
        className="!top-2 !right-2 !left-auto !w-max max-w-[300px]"
        closeButton={false}
        containerId="realTimeAlerts"
        limit={1}
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        position="top-right"
        style={{
          // primary.500
          ["--toastify-color-progress-light" as any]: "#4666F6",
        }}
      />
    </>
  )
}

export default RealtimeAlerts
