import React from "react"

import { Typography, TypographyProps } from "@suraasa/placebo-ui"

type Props = {
  gradient: string
  children: React.ReactNode
  shadow?: boolean
} & TypographyProps

export const useGradientStyle = (gradient: string, shadow = true) => ({
  background: gradient,
  display: "inline-block",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  backgroundClip: "text",
  textShadow: shadow ? "0px 8px 25px rgba(0, 0, 0, 0.15)" : undefined,
})

const GradientTypography = ({
  gradient,
  style,
  shadow = true,
  ...props
}: Props) => {
  const gradientStyles = useGradientStyle(gradient, shadow)

  return (
    <Typography
      style={{
        ...style,
        ...gradientStyles,
      }}
      {...props}
    />
  )
}

export default GradientTypography
