import React from "react"

import { Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import Image from "next/image"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: "white",
    padding: theme.spacing(8, 0),
  },
  overlayText: {
    height: "70px !important",
    width: "246px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75) !important",
    backdropFilter: "blur(9.5px)",
    position: "absolute",
    bottom: 0,
    [theme.breakpoints.only("lg")]: {
      width: "215px",
    },
  },
  designationText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "130%",
    textAlign: "center",
    color: theme.colors.onSurface[800],
  },
  eduImage: {
    height: "245px",
    width: "246px",
    [theme.breakpoints.only("lg")]: {
      height: "224px",
      width: "215px",
    },
  },
}))

const educators = [
  {
    name: "Ms Loulou Hsaiky",
    designation: "Master’s in Education Management, Bachelors in Education.",
    image: "/assets/landing_page/v2/educators/loulou.webp",
  },
  {
    name: "Mr Rishabh Khanna",
    designation: "Cognitive Scientist, Founder and CEO, Suraasa.",
    image: "/assets/landing_page/v2/educators/rishabh.webp",
  },
  {
    name: "Ms Divya Zutshi",
    designation: "Master of Arts in Clinical Psychology, Delhi University",
    image: "/assets/landing_page/v2/educators/divya.webp",
  },
  {
    name: "Mr David Dasari",
    designation: "Master's in Education, University of Phoenix",
    image: "/assets/landing_page/v2/educators/david.webp",
  },
  {
    name: "Ms Dareen Barbour",
    designation:
      "Master’s in Education Management, The British University in Dubai",
    image: "/assets/landing_page/v2/educators/dareen.webp",
  },
  {
    name: "Mr Peter G. Beckway",
    designation:
      "Master's in English Literature, The University of Illinois at Chicago",
    image: "/assets/landing_page/v2/educators/peter.webp",
  },
  {
    name: "Mr Michael Bartlett",
    designation: "Bachelor of Science, Texas A&M University",
    image: "/assets/landing_page/v2/educators/michael.webp",
  },
]

const Educators = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container>
        <Typography
          className="mb-6"
          color="onSurface.800"
          textAlign="center"
          variant="title2"
        >
          Learn from Leading Teacher Education Experts
        </Typography>

        <div className="flex flex-wrap justify-center gap-3 md:mx-1 gap-y-7">
          {educators.map((educator, i) => (
            <div key={i} style={{ position: "relative" }}>
              <div className={classes.eduImage}>
                <Image alt="img" layout="fill" src={educator.image} />
              </div>
              <div className={clsx("p-1", classes.overlayText)}>
                <Typography
                  color="onSurface.800"
                  textAlign="center"
                  variant="strongSmallBody"
                >
                  {educator.name}
                </Typography>
                <div className={classes.designationText} color="onSurface.800">
                  {educator.designation}
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Educators
