/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react"

import { Avatar, Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: "#F1FAFA",
    padding: theme.spacing(8, 0),
  },
  center: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  testimonialContainer: {
    overflow: "hidden",
    minHeight: 236,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },

  "@keyframes marquee": {
    from: {
      transform: "translate3d(0% , 0, 0)",
    },

    to: {
      transform: "translate3d(-50%, 0, 0)",
    },
  },
  sliderContainer: {
    position: "relative",
    height: 265,
  },

  slider: {
    padding: theme.spacing(1, 0),
    position: "absolute",
    top: 0,
    left: 0,
    overflow: "hidden",

    "& .slider-inner": {
      "&:hover": {
        animationPlayState: "paused",
      },
      width: "max-content",
      display: "flex",
      position: "relative",
      animation: "$marquee 60s linear infinite",
    },
  },
}))

const data = [
  {
    quote:
      "During my summer internship, I received a job offer letter with a 200% salary hike.",
    name: "Amira Shah Murad",
    designation: "Educator, StudioLab",
    image: "/assets/landing_page/testimonials/Amira Shah Murad.webp",
  },
  {
    quote:
      "Suraasa has given teachers a hope to make them succeed in their journey and fulfil their dreams.",
    name: "Nikhil Menon",
    designation: "PgCTL Learner (Batch 32)",
    image: "/assets/landing_page/testimonials/Nikhil Menon.webp",
  },
  {
    quote:
      "I have been offered a position at Gems Modern Academy, Kochi, India. I will be joining as the Leader of Key Stage 4 and 5 and the Coordinator of STEAM.",
    name: "Dhanya Geethanali Sasidharan",
    designation: "PgCTL Learner (Batch 17)",
    image:
      "/assets/landing_page/testimonials/Dhanya Geethanali Sasidharan.webp",
  },
  {
    quote:
      "I got a job at Candor International School as IGCSE myp and high school computer science teacher.",
    name: "Krishna Sachdeva",
    designation: "PgCTL Learner (Batch 24)",
    image: "/assets/landing_page/testimonials/Krishna Sachdeva.webp",
  },
  {
    quote:
      "I'm half way in my course and I recently got a job as an MYP Design teacher in a reputed IB school.",
    name: "Chaitra R",
    designation:
      "MYP Design Teacher, Sreenidhi International School, Hyderabad",
    image: "/assets/landing_page/testimonials/Chaitra R.webp",
  },
  {
    quote:
      "I was promoted to the Lead Science Technician within just 2 months of joining Suraasa",
    name: "Shaikh Abdulla",
    designation: "Lead Lab Technician",
    image: "/assets/landing_page/testimonials/Shaikh Abdulla.webp",
  },
  {
    quote: "I achieved my goal of getting a Special Educator job in the USA.",
    name: "Neha Choudhry",
    designation: "Special Need Educator",
    image: "/assets/landing_page/testimonials/Neha Choudhry.webp",
  },
  {
    quote:
      "Suraasa has made me fall in love with teaching, so much that I enjoy every bit of it.",
    name: "Ruchi",
    designation: "PgCTL Learner (Batch 32)",
    image: "/assets/landing_page/testimonials/Ruchi.webp",
  },
  {
    quote:
      "With Suraasa's Boosting Employability, I aced 2 interviews and finally fetched my dream job in the US.",
    name: "Reena Ramesh",
    designation: "Primary Teacher and Special Educator, St. Josephs Academy",
    image: "/assets/landing_page/testimonials/Reena Ramesh.webp",
  },
] as const

const TeacherTestimonials = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.center}>
          <Typography
            className="mb-2"
            style={{ maxWidth: 640 }}
            textAlign="center"
            variant="title1"
          >
            Teachers Trained by Suraasa Reach Newer Heights. Every Day.
          </Typography>

          <Typography
            style={{ maxWidth: 554 }}
            textAlign="center"
            variant="largeBody"
          >
            Our teachers from 50+ nationalities continue to receive promotions,
            salary hikes, and recognition.
          </Typography>
        </div>
      </Container>

      <div className={classes.testimonialContainer}>
        <div className={classes.sliderContainer}>
          <div className={classes.slider}>
            <div className="slider-inner">
              {data.map((d, i) => (
                <Card {...d} key={i} />
              ))}
              {data.map((d, i) => (
                <Card {...d} key={i} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const useCardStyles = createUseStyles(theme => ({
  root: {
    marginRight: theme.spacing(2.5),
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    backgroundColor: "white",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    maxWidth: "354px",

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  quote: {
    fontFamily: "Lora",
  },
}))

const Card = ({ quote, name, image, designation }: typeof data[number]) => {
  const classes = useCardStyles()

  return (
    <div className={classes.root}>
      <Typography className={clsx(classes.quote, "mb-4")} variant="largeBody">
        “{quote}”
      </Typography>

      <div className="flex items-end">
        <div className="mr-2 shrink-0">
          <Avatar src={image} />
        </div>

        <div>
          <Typography className="mb-0.25">{name}</Typography>
          <Typography variant="strong">{designation}</Typography>
        </div>
      </div>
    </div>
  )
}

export default TeacherTestimonials
